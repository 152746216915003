import {Injectable} from "@angular/core";
import {DesktopService} from "./desktop.service";
import {PropertyStub} from "@icure/api/icc-api/model/PropertyStub";
import {LicenceInfos} from "../models/user.model";
import {ApplicationSettings} from "@icure/api";

export enum AppSettingsEnum {
  '1733_CONFIG' = 'APPSETTINGS|1733-CONFIG|1.0.0',
  'ACTIVE_FEATURES' = 'APPSETTINGS|ACTIVE-FEATURES|1.0.0',
  'AGENDA_OPTIONS' = 'APPSETTINGS|AGENDA-OPTIONS|1.0.0',
  'BEOOGO_ACC' = 'APPSETTINGS|BEOOGO|ACC|1.0.1',
  'BEOOGO_PRD' = 'APPSETTINGS|BEOOGO|PRD|1.0.1',
  'CONNECTION_MODE' = 'APPSETTINGS|CONNECTION-MODE|1.0.0',
  'CUSTOMER_INVOICING' = 'APPSETTINGS|CUSTOMER-INVOICING|1.0.0',
  'DYNAMIC_FORM' = 'APPSETTINGS|DYNAMIC-FORM|1.0.0',
  'EFORM_ACC' = 'APPSETTINGS|EFORM|ACC|1.0.0',
  'EFORM_PRD' = 'APPSETTINGS|EFORM|PRD|1.0.0',
  'GENERALSETTINGS' = 'APPSETTINGS|GENERALSETTINGS|ACC|1.0.0',
  'GROUP_PRACTICE' = 'APPSETTINGS|GROUP-PRACTICE|1.0.0',
  'LICENCE' = 'APPSETTINGS|LICENCE|1.0.0',
  'MEDISPRING_VERSION' = 'APPSETTINGS|MEDISPRING-VERSION|1.0.0',
  'DATABASE_ORIGIN' = 'APPSETTINGS|DATABASE-ORIGIN|1.0.0',
}

export interface DatabaseOriginInfos {
  databaseOrigin: string;
  iCureMacDatabase: boolean;
}

export interface CustomerInvoicingInfos{
  invoicingAddressAndUserRelationships: {
    addressIdByUserId: any;
    relationshipValidationByUserId: any;
    userIdsByAddressId: any;
  }
  invoicingAddresses: {
    [key: string]: InvoicingAddress
  };
}

export interface InvoicingAddress {
 city: string;
 country: string;
  createdBy: string;
  createdOn: number;
  dataSource: string;
  emailAddress: string;
  externalId: string;
  id: string;
  modifiedBy: string;
  modifiedOn: number;
  name: string;
  phoneNumber: string;
  postalCode: string;
  street: string;
  validateBy: string;
  validateOn: string;
  vat: string;
}

@Injectable({
  providedIn: 'root',
})
export class ApplicationSettingsService {

  constructor(
    private desktopService: DesktopService,
  ) {
  }

  public async getAllApplicationSettings(groupId: string, groupPassword: string, cluster: string){
    const appSettingsResponse = await this.desktopService.getAllApplicationSettings(groupId, groupPassword, cluster);
    return appSettingsResponse?.content;
  }

  public async getApplicationSettingsById(groupId: string, groupPassword: string, cluster: string, applicationSettingId: string){
    const appSettingsResponse = await this.desktopService.getApplicationSettingById(groupId, groupPassword, cluster, applicationSettingId);
    return appSettingsResponse?.content;
  }

  public getDatabaseOriginInfos(property: any): DatabaseOriginInfos{
    let databaseOriginInfos : DatabaseOriginInfos = {
      databaseOrigin: '',
      iCureMacDatabase: false
    };

    if(property){
      try{
        databaseOriginInfos = {
          databaseOrigin: property?.settings?.databaseOrigin,
          iCureMacDatabase: property?.settings?.iCureMacDatabase
        }
      }catch (e) {
        console.warn('Cannot parse database origin infos');
      }
    }

    return databaseOriginInfos;
  }

  public parseCustomerInvoicingSetting(property: ApplicationSettings): CustomerInvoicingInfos{
    let customerInvoicing: any = {};
    try{
      customerInvoicing = JSON.parse(property?.settings?.['data']!!);
    }catch (e) {
      console.warn('Cannot parse customer invoicing settings');
    }
    return customerInvoicing;
  }
}
