<table mat-table matSort [dataSource]="dataSource">
  <ng-container matColumnDef="configId">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Id de la config</th>
    <td mat-cell *matCellDef="let element">
      <div class="flex">
        <span class="circle green" title="Indexation terminée" *ngIf="element?.isKrakenInitialized === true"></span>
        <span class="circle red" title="Indexation non terminée, connexion au cloud uniquement" *ngIf="element?.isKrakenInitialized === false && element?.installationType !== 'client'"></span>
        <span class="circle black" title="Information non disponible" *ngIf="(!element?.isKrakenInitialized && element?.isKrakenInitialized !== false && element?.isKrakenInitialized !== true && element?.installationType!== 'client')"></span>
        <span class="circle border-black" title="Installation de type client" *ngIf="element?.installationType === 'client'"></span>
        <span class="square green" title="Indexation terminée" *ngIf="element?.isMauriceInitialized === true"></span>
        <span class="square red" title="Indexation non terminée, connexion au cloud uniquement" *ngIf="element?.isMauriceInitialized === false && element?.installationType !== 'client'"></span>
        <span class="square black" title="Information non disponible" *ngIf="(!element?.isKrakenInitialized && element?.isMauriceInitialized !== false && element?.isMauriceInitialized !== true && element?.installationType!== 'client')"></span>
        <span class="square border-black" title="Installation de type client" *ngIf="element?.installationType === 'client'"></span>
        {{element?.configId}}
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="currentVersion">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Version actuelle</th>
    <td mat-cell *matCellDef="let element">{{element?.currentVersion}}</td>
  </ng-container>
  <ng-container matColumnDef="couchDBVersion">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Version couchDb</th>
    <td mat-cell *matCellDef="let element">{{element?.couchDBVersion}}</td>
  </ng-container>
  <ng-container matColumnDef="electronVersion">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Version electron</th>
    <td mat-cell *matCellDef="let element">{{element?.electronVersion}}</td>
  </ng-container>
  <ng-container matColumnDef="cpuModel">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>CPU</th>
    <td mat-cell *matCellDef="let element">{{element?.cpuModel}}</td>
  </ng-container>
  <ng-container matColumnDef="cpuCount">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Coeur</th>
    <td mat-cell *matCellDef="let element">{{element?.cpuCount}}</td>
  </ng-container>
  <ng-container matColumnDef="ramQty">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>RAM</th>
    <td mat-cell *matCellDef="let element">{{getBytes(element?.ramQty)}}</td>
  </ng-container>
  <ng-container matColumnDef="diskSize">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Espace disque total</th>
    <td mat-cell *matCellDef="let element">{{getBytes(element?.diskSize)}}</td>
  </ng-container>
  <ng-container matColumnDef="diskSapceFree">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Espace disque libre</th>
    <td mat-cell *matCellDef="let element">{{getBytes(element?.diskSapceFree)}}</td>
  </ng-container>
  <ng-container matColumnDef="diskPath">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Type d'installation</th>
    <td mat-cell *matCellDef="let element">{{element?.installationType}}</td>
  </ng-container>
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <div class="btn-flex-container">
        <button mat-icon-button title="Plus d'informations" (click)="openInfoDialog(element)">
          <mat-icon>info</mat-icon>
        </button>
      </div>
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="openConfirmDesactivationDialog(element)">
          <mat-icon>remove_circle</mat-icon>
          <span>Désactiver la configuration</span>
        </button>
      </mat-menu>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

