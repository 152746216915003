import {Component, EventEmitter, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {UserService} from "../../../../../../lib/services/user.service";
import {HcpService} from "../../../../../../lib/services/hcp.service";
import {ApiService} from "../../../../../../lib/services/api.service";
// @ts-ignore
import {v4 as uuidv4} from 'uuid';
import {HealthcareParty} from "@icure/api";
import * as _ from 'lodash';
import {MessageTypeEnum} from "../../../database-group/database-group.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {User} from "@icure/api/icc-api/model/User";
import {MatCheckboxChange} from "@angular/material/checkbox";

interface ParentIdInfos{
    hcpId: string;
    name: string;
}

interface RoleInfos{
  id: string;
  defaultChecked: boolean;
  available: boolean;
  description: string;
}

@Component({
  selector: 'ms-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.scss']
})
export class UserDialogComponent implements OnInit{
  public onCreatedUser = new EventEmitter();
  public userForm: FormGroup = new FormGroup({});
  public availableParentId: ParentIdInfos[] = [];
  public newUserInfos: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    passwordTemp: string;
    parentId: string;
    ssin: string;
    nihii: string;
    roles: string[];
  } = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    passwordTemp: '',
    parentId: '',
    ssin: '',
    nihii: '',
    roles: ['MS_PRACTICIAN']
  }

  public availableRoles: RoleInfos[]  = [
    {
      id: 'MS_STUDENT_PRACTICIAN',
      defaultChecked: false,
      available: true,
      description: 'Etudiant en médecine'
    },
    {
      id: 'MS_PRESCRIPTOR',
      defaultChecked: false,
      available: true,
      description: 'Prescripteur'
    },
    {
      id: 'MS_USER_MANAGER',
      defaultChecked: false,
      available: true,
      description: 'Gestionnaire d\'utilisateurs'
    },
    {
      id: 'MS_SECRETARY',
      defaultChecked: false,
      available: true,
      description: 'Secrétaire'
    },
    {
      id: 'MS_PRACTICIAN',
      defaultChecked: true,
      available: true,
      description: 'Praticien'
    },
    {
      id: 'MS_ADMIN',
      defaultChecked: false,
      available: true,
      description: 'Administrateur'
    }
  ];
  constructor(
    public dialogRef: MatDialogRef<UserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    private hcpService: HcpService,
    private apiService: ApiService,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit() {
    // @ts-ignore
    this.availableParentId =  _.uniqBy(this.data?.listOfHcps?.map((hcp: HealthcareParty) => hcp?.parentId ? ({hcpId: hcp?.parentId, name: `${hcp?.firstName} ${hcp?.lastName}`}) : null)?.filter((hcp: ParentIdInfos) => !!hcp?.hcpId), 'hcpId') || []
    this.newUserInfos.parentId = this.availableParentId[0]?.hcpId;
  }

  public roleChanged(e: MatCheckboxChange): void{
    if(e.checked === true){
      this.newUserInfos.roles.push(e.source.value);
    }else{
      this.newUserInfos.roles.splice(this.newUserInfos.roles.indexOf(e.source.value), 1);
    }
  }

  public async createUser(){
    if(this.newUserInfos?.firstName !== '' && this.newUserInfos?.lastName !== '' && this.newUserInfos?.email !=='' && (this.newUserInfos?.password !== '' || this.newUserInfos?.passwordTemp !== '') && this.newUserInfos?.roles.length > 0 ){
      try {
        const newInstanceOfHcp = {
          id: uuidv4(),
          firstName: this.newUserInfos?.firstName,
          lastName: this.newUserInfos?.lastName,
          ssin: this.newUserInfos?.ssin || '',
          parentId: '',
          nihii: this.newUserInfos?.nihii || ''
        }
        if(this.newUserInfos?.parentId !== ''){
          newInstanceOfHcp.parentId = this.newUserInfos?.parentId
        }

        const hcp = await this.hcpService.addHcpInGroup(this.data?.groupInfos?.id, newInstanceOfHcp);
        console.log(this.newUserInfos);
        // @ts-ignore
        const user = await this.userService.addUserInGroup(this.data?.groupInfos?.id, {
          id: uuidv4(),
          healthcarePartyId: hcp?.id!!,
          name: `${this.newUserInfos?.firstName} ${this.newUserInfos?.lastName}`,
          email: this.newUserInfos.email,
          login: this.newUserInfos.email,
          // @ts-ignore
          autoDelegations: { ["all"]: [hcp?.id, newInstanceOfHcp.parentId ? newInstanceOfHcp?.parentId : null]?.filter(id => !!id) } || {},
          type: User.TypeEnum.Database,
          status: User.StatusEnum.ACTIVE,
          passwordHash: this.userForm.get('password')?.value,
          roles: this.newUserInfos?.roles,
          applicationTokens: this.newUserInfos?.passwordTemp !== '' ? {"tmp" : this.newUserInfos.passwordTemp} : {}
        });

        this.closeDialog();
        this.onCreatedUser.emit({
          status: 'success',
        })
        this.displayWarningMessage(`L'utilisateur: ${this.newUserInfos?.firstName} ${this.newUserInfos?.lastName} a bien été créé dans la db`, MessageTypeEnum.SUCCESS);
      }catch (e){
        console.error(e);
        this.closeDialog();
        this.displayWarningMessage(`Erreur de la création de l'utilisateur ${this.newUserInfos?.firstName} ${this.newUserInfos?.lastName}`, MessageTypeEnum.ERROR);
      }

    }
  }

  public closeDialog(){
    this.dialogRef.close();
  }

  private displayWarningMessage(message: string, messageType: MessageTypeEnum){
    this.snackBar.open(message, '', {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: [messageType]
    });
  }

}
